// Body
$body-bg: #f0f1f4;

//aside nav
$aside-width: 120px;

// Typography
$font-family-sans-serif: 'Montserrat', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

//
// Color system
//

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #ddd !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #4582ec !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #d9534f !default;
$orange:  #fd7e14 !default;
$yellow:  #f0ad4e !default;
$green:   #02b875 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$primary:       $blue !default;
$secondary:     $gray-500 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$min-contrast-ratio:   1.85 !default;

// border rounded
$enable-rounded: true;
