//Fonts
@import 'fonts';

// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap';

// Material Icons
@import 'material-icons';

// Boostrap-select
@import "bootstrap-select/sass/bootstrap-select.scss";

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }
.material-icons{
	display: inline-flex;
	vertical-align: top;
}

/* Code mirror editor */
@import 'codemirror/lib/codemirror.css';
@import 'codemirror/theme/dracula.css';


table thead th{
    vertical-align: text-top;
}

/* CUSTOM */
.card{
	.card-header{
		display: flex;
		justify-content: space-between;
		align-items: center;
		.actionbar{
			.material-icons{
				vertical-align: middle;
			}
		}
	}
}


//LAYOUT
#app{
	display: flex;
	aside{
		@include media-breakpoint-up(md) {
			width: $aside-width;
			position: fixed;
			z-index: 999;
			left: 0;
			top:0;
			bottom: 0;
			background: $light;
			height: 100vh;
		}
	}
	#secondary-nav{
		.nav{
			width: 100%;
			display: flex;
			justify-content: space-between;
		}
	}
	div#main-container{
		@include media-breakpoint-up(md) {
			margin-left: $aside-width;
		}
	}
}
//STREAMS
#streams{
	.streams{
		img{
			max-width: 100%;
			height: auto;
		}
	}

}

//ADDRESSBOOK
#address-detail{
	&>.card{
		position: sticky;
		top:10px;
	}
}

//Lunches
#my-lunches{
	&>.card{
		position: sticky;
		top:10px;
	}
}

//NEWS
#news-detail{
	.img-container{
		display: flex;
		align-items: center;
		justify-content: center;
		max-height: 50vh;
		overflow: hidden;
		img{
			max-width: 80vw;
			max-height: 80vh;
		}
	}
}
